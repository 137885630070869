import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import CompletedImg from "assets/completed.svg";
//Selectors
import { selectSuccessMessage } from "./successSelectors";

function Success({ successMessage }) {
  if (!successMessage) return <Redirect to="/signup" />;

  return (
    <div
      style={{
        padding: "0 2em",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        justifyContent: "space-evenly",
      }}
    >
      <img src={CompletedImg} alt="Registro completado" />
      <span
        style={{
          fontFamily: "Josefin Sans, sans-serif",
          fontSize: "2em",
          textAlign: "center",
        }}
      >
        {successMessage}
      </span>
    </div>
  );
}

const mapStateToProps = (state) => ({
  successMessage: selectSuccessMessage(state),
});

export default connect(mapStateToProps)(Success);
