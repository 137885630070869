import { Carousel } from "antd";
import React from "react";
import Navbar from "components/Navbar/Navbar";
import "./Home.css";
import phtSlider1 from "assets/slider/slider1.jpg";
import phtSlider2 from "assets/slider/slider2.png";
import phtSlider3 from "assets/slider/slider3.png";
import phtSlider4 from "assets/slider/slider4.jpg";
import illustrations from "assets/asset4.svg";
import mvv from "assets/mvv.png";

const contentStyle = {
  height: "calc(100vh - 85px)",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

export default function Home() {
  return (
    <>
      <Navbar></Navbar>
      <Carousel autoplay>
        <div>
          <h3 style={contentStyle}>
            <img className="immg" src={phtSlider1} alt="" />
          </h3>
        </div>
        <div>
          <h3 style={contentStyle}>
            <img className="immg" src={phtSlider2} alt="" />
          </h3>
        </div>
        <div>
          <h3 style={contentStyle}>
            <img className="immg" src={phtSlider3} alt="" />
          </h3>
        </div>
        <div>
          <h3 style={contentStyle}>
            <img className="immg" src={phtSlider4} alt="" />
          </h3>
        </div>
      </Carousel>

      <div className="container">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#ED008C"
            fill-opacity="1"
            d="M0,192L60,202.7C120,213,240,235,360,229.3C480,224,600,192,720,165.3C840,139,960,117,1080,106.7C1200,96,1320,96,1380,96L1440,96L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
          ></path>
        </svg>

        <div className="section">
          <h1>SOBRE NOSOTROS</h1>
          <div className="contentText">
            <div className="descrp">
              <h2>¿Quiénes somos?</h2>
              <p>
                Somos una asociación cristiana dedicada a la formación y apoyo a
                la iglesia en su trabajo con la niñez y la juventud, ofreciendo
                diversos recursos y metodologías pedagógicas con el fin de
                alcanzar y transformar la vida de cada maestro o maestras, niño,
                niña y adolescente, con el que se tengamos contacto
              </p>
              <h2>¿Cómo lo logramos?</h2>
              <p>
                Hemos establecido 4 líneas estratégicas de trabajo con el fin de
                desarrollar los proyectos de una manera que nos permita ver a
                los niños, niñas y adolescentes alcanzando su máximo potencial.
              </p>
            </div>
            <div className="descrp2">
              <ul>
                <li>DISCIPULADO</li>
                <li>EVANGELISMO</li>
                <li>RECURSOS</li>
                <li>ACCIÓN</li>
                <li>COLECTIVA</li>
              </ul>
            </div>
          </div>
          <div>
            <img className="ilusimg" src={illustrations}></img>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className="section2">
          <div>
            <img className="mvv" src={mvv}></img>
            <div className="contentText">
              <div className="descrp">
                <h2>VISIÓN</h2>
                <p>
                  Ser un ministerio de avanzada en la formación y preparación de
                  la niñez y adolescencia en su plan de vida cimentado en la
                  palabra de Dios.
                </p>
                <h2>MISIÓN</h2>
                <p>
                  Fortalecer y ofrecer a los ministerios infanto - juveniles
                  herramientas útiles y prácticas para desarrollar y potenciar
                  su labor en la formación cristiana.
                </p>
              </div>
              <div>
                <h2>NUESTROS VALORES</h2>
                <ul>
                  <li>El servicio</li>
                  <li>Excelencia</li>
                  <li>Acción</li>
                  <li>Entrega</li>
                  <li>La innovación</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#280048"
            fill-opacity="1"
            d="M0,192L26.7,186.7C53.3,181,107,171,160,149.3C213.3,128,267,96,320,96C373.3,96,427,128,480,165.3C533.3,203,587,245,640,240C693.3,235,747,181,800,165.3C853.3,149,907,171,960,197.3C1013.3,224,1067,256,1120,272C1173.3,288,1227,288,1280,266.7C1333.3,245,1387,203,1413,181.3L1440,160L1440,320L1413.3,320C1386.7,320,1333,320,1280,320C1226.7,320,1173,320,1120,320C1066.7,320,1013,320,960,320C906.7,320,853,320,800,320C746.7,320,693,320,640,320C586.7,320,533,320,480,320C426.7,320,373,320,320,320C266.7,320,213,320,160,320C106.7,320,53,320,27,320L0,320Z"
          ></path>
        </svg>
      </div>
    </>
  );
}
