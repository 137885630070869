import React from "react";
import "./Login.css";
import Navbar from "components/Navbar/Navbar";

function Login() {
  return (
    <>
      <Navbar></Navbar>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <h1>Bienvenido a LOGIN</h1>
    </>
  );
}

export default Login;
