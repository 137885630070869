import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "assets/logo-500px-200px.png";

const Navbar = () => {
  return (
    <nav id="nav" className="nav1">
      <div className="nav__container">
        <div className="nav__logo">
          <Link to="/singup">
            <img className="logo" src={logo} alt="Logo" />
          </Link>
        </div>

        <div className="nav__links" id="enlaces">
          {/* <Link to="/">Inicio</Link>
                    <Link to="/login">Iniciar sesión</Link> */}

          {/* <Link to="/signup">Registrarse</Link> */}

          {/* <Link to="/more">Mas...</Link> */}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
