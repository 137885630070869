//Libs
import React from "react";
import { connect } from "react-redux";
//Services
import { onSignupService } from "../User/userServices";
//Validators
import { passwordLengthIsValid } from "../User/userValidators";
//Actions
import { setSuccessMessage } from "../Success/successActions";
//Components
import { Input, Button, Tooltip } from "antd";
import {
  UserOutlined,
  PhoneOutlined,
  GoogleOutlined,
  KeyOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import logo from "assets/logo.png";
import Navbar from "components/Navbar/Navbar";
//Hooks
import useForm from "hooks/useForm";
//CSS
import "./Signup.css";

const Signup = ({ history, setSuccessMessage }) => {
  const [form, onChangeForm, resetForm] = useForm();
  const confirmPassword = form.password === form.confirmPassword;

  const onSignup = (form) => {
    onSignupService(form)
      .then((res) => {
        resetForm();
        setSuccessMessage(`Bienvenido ${res.name}, gracias por registrarse!`);
        history.push("/success");
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Navbar />
      <div className="signup-container">
        <img className="img" alt="" src={logo} />
        {/* NAME */}
        <Input
          className="input"
          name="name"
          placeholder="Nombre*"
          value={form.name}
          prefix={<UserOutlined className="site-form-item-icon" />}
          onChange={(e) => onChangeForm({ [e.target.name]: e.target.value })}
        />
        {/* FIRST LAST NAME */}
        <Input
          className="input"
          name="firstLastName"
          placeholder="Primer Apellido*"
          value={form.firstLastName}
          prefix={<UserOutlined className="site-form-item-icon" />}
          onChange={(e) => onChangeForm({ [e.target.name]: e.target.value })}
        />
        {/* SECOND LAST NAME */}
        <Input
          className="input"
          name="secondLastName"
          placeholder="Segundo Apellido"
          value={form.secondLastName}
          prefix={<UserOutlined className="site-form-item-icon" />}
          onChange={(e) => onChangeForm({ [e.target.name]: e.target.value })}
        />
        {/* EMAIL */}
        <Input
          className="input"
          name="email"
          type="email"
          placeholder="Email*"
          value={form.email}
          prefix={<GoogleOutlined className="site-form-item-icon" />}
          onChange={(e) => onChangeForm({ [e.target.name]: e.target.value })}
        />
        {/* PHONE */}
        <Input
          className="input"
          name="phone"
          placeholder="Teléfono*"
          value={form.phone}
          prefix={<PhoneOutlined className="site-form-item-icon" />}
          onChange={(e) => onChangeForm({ [e.target.name]: e.target.value })}
        />
        {/* PASSWORD */}
        <Input
          className="input"
          type={form.showPassword ? "text" : "password"}
          name="password"
          placeholder="Contraseña*"
          value={form.password}
          onChange={(e) => onChangeForm({ [e.target.name]: e.target.value })}
          prefix={<KeyOutlined className="site-form-item-icon" />}
          suffix={
            !form.showPassword ? (
              <EyeOutlined
                className="site-form-item-icon"
                onClick={() =>
                  onChangeForm({ showPassword: !form.showPassword })
                }
              />
            ) : (
              <EyeInvisibleOutlined
                className="site-form-item-icon"
                onClick={() =>
                  onChangeForm({ showPassword: !form.showPassword })
                }
              />
            )
          }
        />
        {/* CONFIRM PASSWORD */}
        <Input
          className="input"
          type={form.showPassword ? "text" : "password"}
          name="confirmPassword"
          placeholder="Confirmar Contraseña*"
          value={form.confirmPassword}
          onChange={(e) => onChangeForm({ [e.target.name]: e.target.value })}
          prefix={<KeyOutlined className="site-form-item-icon" />}
          suffix={
            form.confirmPassword?.length > 0 && !confirmPassword ? (
              <Tooltip placement="top" title={"Las contraseñas no coinciden"}>
                <WarningOutlined style={{ cursor: "pointer" }} />
              </Tooltip>
            ) : (
              <div />
            )
          }
        />

        {/* BUTTON */}
        <Tooltip
          placement="top"
          title={"Debe completar todos los campos requeridos [*]"}
        >
          <Button
            disabled={
              !form.name ||
              !form.firstLastName ||
              !form.email ||
              !form.password ||
              !confirmPassword
            }
            className="btn"
            onClick={() => {
              if (passwordLengthIsValid(form.password)) {
                onSignup(form);
              }
            }}
          >
            Registrarme
          </Button>
        </Tooltip>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  setSuccessMessage: (payload) => dispatch(setSuccessMessage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
