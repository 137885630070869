//Types
import UserActionTypes from "./userTypes";

const INITIAL_STATE = {
  //LoggedIn: Object || LoggedOut: null
  currentUser: null,
  //Main and control message from system to user
  systemMessage: {},
};

const userReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case UserActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: payload,
      };
    case UserActionTypes.SHOW_SYSTEM_MESSAGE:
      return {
        ...state,
        systemMessage: payload,
      };
    case UserActionTypes.LOGOUT:
      return {
        ...state,
        currentUser: null,
      };
    default:
      return state;
  }
};

export default userReducer;
