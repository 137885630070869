//Services
import Mixpanel from "services/mixpanelService";
import { fetchPlus } from "../../services/fetchService";
import { getUserUrls, getSignupOptions } from "./userOptions";

export const onSignupService = (payload) => {
  return new Promise((resolve, reject) => {
    fetchPlus(getUserUrls().signup, getSignupOptions(payload))
      .then(async (res) => {
        if (typeof res === "number") {
          if (res === 409) {
            reject("El usuario ya se encuentra registrado");
          }
          if (res !== 200) {
            Mixpanel.track(Mixpanel.TYPES.UNSUCCESSFUL_SIGNUP);
            reject(
              "Hubo un problema al crear su usuario. Por favor vuelva a intentarlo"
            );
          }
        }

        const { token, id, name, firstLastName, email } = res;

        Mixpanel.identify(id);
        Mixpanel.track(Mixpanel.TYPES.SUCCESSFUL_SIGNUP);
        Mixpanel.people.set({
          $first_name: name,
          $last_name: firstLastName,
        });

        resolve({ token, id, name, firstLastName, email });
      })
      .catch((err) => {
        Mixpanel.track(Mixpanel.TYPES.UNSUCCESSFUL_SIGNUP);
        reject(err);
      });
  });
};
