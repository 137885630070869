//Types
import successActionTypes from "./successTypes";

const INITIAL_STATE = {
  successMessage: null,
};

const successReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case successActionTypes.SET_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessage: payload,
      };
    default:
      return state;
  }
};

export default successReducer;
