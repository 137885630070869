import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
const env_check = process.env.NODE_ENV === "production";

const mixpanelService = Object.freeze({
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
  TYPES: {
    //SIGNUP
    SUCCESSFUL_SIGNUP: "SUCCESSFUL_SIGNUP",
    UNSUCCESSFUL_SIGNUP: "UNSUCCESSFUL_SIGNUP",
    //LOGIN
    SUCCESSFUL_LOGIN: "SUCCESSFUL_LOGIN",
    UNSUCCESSFUL_LOGIN: "UNSUCCESSFUL_LOGIN",
    //FORGOT PASSWORD
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    SUCCESSFUL_RESET_PASSWORD: "SUCCESSFUL_RESET_PASSWORD",
    UNSUCCESSFUL_RESET_PASSWORD: "UNSUCCESSFUL_RESET_PASSWORD",
  },
});

export default mixpanelService;
