//Libs
import { createStore, applyMiddleware } from "redux";
import { persistStore } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
// import thunk from "redux-thunk";

//Root Reducer
import rootReducer from "./rootReducer";

//Middlewares
const middlewareList = []; //[thunk];

const middlewares = applyMiddleware(...middlewareList);

export const store =
  process.env.NODE_ENV === "production"
    ? createStore(rootReducer, middlewares)
    : createStore(rootReducer, composeWithDevTools(middlewares));

export const persistor = persistStore(store);
