//Libs
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
//Reducers
import userReducer from "../screens/User/userReducer";
import successReducer from "../screens/Success/successReducer";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["user"],
};

const rootReducer = combineReducers({
  user: userReducer,
  success: successReducer,
});

export default persistReducer(persistConfig, rootReducer);
