import CONFIG from "config";

const { API_URL } = CONFIG;

export const getUserUrls = () => {
  const usersBase = `${API_URL}/users/v1`;
  return {
    signup: `${usersBase}/signup`,
  };
};

export const getSignupOptions = (payload) => ({
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
  body: JSON.stringify({
    name: payload.name,
    firstLastName: payload.firstLastName,
    secondLastName: payload.secondLastName,
    password: payload.password,
    phone: payload.phone,
    email: payload.email,
  }),
});
