export const fetchPlus = (
  url,
  options = {},
  retries = process.env.REACT_APP_NUMBER_OF_REQUEST_ATTEMPTS
) =>
  fetch(url, options)
    .then(async (res) => {
      if (res.ok) {
        return await res.json();
      } else {
        throw res.status;
      }
    })
    .catch((err) => {
      if (retries > 0) {
        return fetchPlus(url, options, retries - 1);
      }
      throw err;
    });
