//Libs
import React from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";

//Selectors
import { selectCurrentUser } from "screens/User/userSelectors";

//Components
import Login from "screens/Login/Login";
import Home from "screens/Home/Home";
import SingUp from "screens/Signup/Signup";
import Success from "screens/Success/Success";

const LogoutRoutes = () => {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/signup" component={SingUp} />
      <Route path="/success" component={Success} />
      <Route path="/home" component={Home} />
      <Route path="/" render={() => <Redirect to="/signup" />} />
    </Switch>
  );
};

const LoginRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
    </Switch>
  );
};

function App({ currentUser }) {
  return !currentUser ? <LogoutRoutes /> : <LoginRoutes />;
}

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
});

export default connect(mapStateToProps)(App);
